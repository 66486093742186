/**
 * Created by juangirini on 05/01/2016.
 */
$(document).ready(function(){
    $('#confirm-delete').on('show.bs.modal', function(e) {
        $(this).find('.deleteForm').attr('action', $(e.relatedTarget).data('href'));
    });
    $('.cp').colorpicker();
    $('input#image').change(function(){
        $('#current-image').html('&nbsp;');
    });
});
